import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Flex, Box } from 'rebass'
import Hero from '../components/base/Hero'
import Link from '../components/base/Link'
import BackedByIag from '../components/base/BackedByIag'

const FAQs = () => (
  <StaticQuery query={graphql`
    query StatementsQuery {
      allDatoCmsStatement {
        edges {
          node {
            id
            name
            documents {
              id
              name
              url
            }
          }
        }
      }
    }
  `}
  render={data => (
    <>
      <Hero sx={{ py: 0 }}>
        <Heading as="h1" variant="hero.heading">Product Disclosures</Heading>
        <Heading as="h2" variant="hero.subheading">When making decisions about a product, always read the Product Disclosure Statement (PDS).</Heading>
      </Hero>
      <Box my={5} variant="fixed">
        {data.allDatoCmsStatement.edges.map(({ node: statement }) =>
          <Box key={statement.id} mb={80}>
            <Heading
              as="h3"
              variant='heading'
              fontWeight={500}
              lineHeight={1.6}
            >
              {statement.name}
            </Heading>
            <Flex my={2} mx={-1}>
              {statement.documents.map(doc => (
                <Link
                  key={doc.id}
                  href={doc.url}
                  as="a"
                  variant="button"
                  sx={{ flex: 1, mx: 1 }}
                >
                  Download {doc.name}
                </Link>
              ))}
            </Flex>
          </Box>
        )}
      </Box>
      <BackedByIag mt={5} />
    </>
  )}
  />
)

export default FAQs
